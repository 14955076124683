import React, { useEffect, useState } from 'react';

import {
	AppContainer,
	CreationBoard,
	Login
} from './'

export default function Main() {
	const [loggedIn, setLoggedIn] = useState(false);

	useEffect(() => {
		console.log(`Logged In: ${loggedIn}`)
	}, [loggedIn]);

  return (
    loggedIn
		? <AppContainer>
			<CreationBoard />
		</AppContainer>
		: <Login onLogin={ () => { setLoggedIn(!loggedIn) }} />
  );
}
