import { ulid } from 'ulid'
import _ from "underscore";
import { Models } from './';
import { Services } from './';
import { ContentCreationResult } from './models';
import { ContentCreationCreativity } from './models/contentCreationCreativity';

interface ContentCreationServices {
	images: Services.ContentCreation.Service,
	prompts: Services.PromptVariations.Service,
	results: Services.Results.Service
}

export interface UseCases {
	createContent: (request: Models.ContentCreationRequest, onResultsUpdated?: (props: { request: Models.ContentCreationRequest, results: ContentCreationResult[] }) => void) => Promise<void> 
	createContentRequest: (prompt: string) => Models.ContentCreationRequest
}

const promptExpansions = [
	({ prompt } : { prompt: string }) => `${prompt}, sunrise lighting, volumetric light, hyper realistic, octane render`,
	({ prompt } : { prompt: string }) => `${prompt}. Award winning photograph`,
	// ({ prompt } : { prompt: string }) => `${prompt}. Digital Illustration by Simon Stålenhag.`,
	({ prompt } : { prompt: string }) => `gorgeous cinematography ${prompt}, post-production, depth of field, movie, cinema, professional color grading, 35mm lens, very detailed, film grain`,
	({ prompt } : { prompt: string }) => `${prompt}, ultra high quality, by miyazaki and makoto shinkai, anime screenshot, colorful, artstation, pixiv, 8k `,
	({ prompt } : { prompt: string }) => `a photorealistic hyperrealistic render of ${prompt}, vivid colors, outer space, by greg rutkowski, craig mullins, fenghua zhong, artgerm, jaques - louis david, contrast and shadows, volumetric, cinematic lighting, light rays, ray tracing`,
	// ({ prompt } : { prompt: string }) => `DVD screengrab, 1989 Studio Ghibli anime movie depicting ${prompt}`,
	// ({ prompt } : { prompt: string }) => `DVD screengrab, 1989 Disney animation movie depicting ${prompt}`,
	({ prompt } : { prompt: string }) => `${prompt}. Digital art, highly detailed, trending on artstation, midjourney`,
	({ prompt } : { prompt: string }) => `a dark, monochrome, glass negative, silver gelatine realistic, supersharp, realistic, photographic daguerreotype of ${prompt}, shallow depth of field `,
	// ({ prompt } : { prompt: string }) => `movie poster title "${prompt}", painted by artgerm, Alphonse Mucha, Akihiko yoshida, sakimichan, krenz cushart, vibrant colors, hyperrealistic, fine details`,
	({ prompt } : { prompt: string }) => `a magnificent ${prompt}, grim - lighting, high - contrast, intricate, elegant, highly detailed, centered, digital painting, concept art, smooth, sharp focus, illustration, artgerm, by Gal Barkan, tomasz alen kopera, Thomas Dubois, Rutger van de Steeg, peter mohrbacher, wlop, boris vallejo, daniel liang, Eddie Mendoza, AgusSW, Stephan Martiniere and Simon Stålenhag`,
	// ({ prompt } : { prompt: string }) => `A digital painting of 19th century ${prompt}, trains, Victorian illustration, illustrated, trending on art station, sharp focus, highly detailed`,
	({ prompt } : { prompt: string }) => `${prompt}, fantasy world, mystical, photorealistic, smoky, hd, 4k, magical, detailed`,
	({ prompt } : { prompt: string }) => `${prompt}, gloomy, dark, wide angle, humid, unreal engine, daunting, silent hill, by paul chadeisson, atmospherical, concept art, high detail, intimidating, cinematic, octane render`,
	({ prompt } : { prompt: string }) => `${prompt}, 19th century, illustration, oil painting, dark, highly detailed, intricate, trending on arstation, smooth, sharp focus`,
	// ({ prompt } : { prompt: string }) => `${prompt} in space, intricate, cinematic lighting, highly detailed, digital painting, concept art, smooth, sharp focus, illustration, illustrated by Sophie Anderson, Mark Arian`,
	({ prompt } : { prompt: string }) => `${prompt}, intricate, cinematic lighting, highly detailed, digital painting, concept art, smooth, sharp focus, illustration, illustrated by Sophie Anderson, Mark Arian`,
	({ prompt } : { prompt: string }) => `${prompt} intricate, cinematic lighting, highly detailed, digital painting, concept art, smooth, sharp focus, illustration, illustrated by Paul Chadeison`,
	// ({ prompt } : { prompt: string }) => `A digital artwork of ${prompt}`,
	({ prompt } : { prompt: string }) => `Oil pastel ${prompt}, green yellow gradient background`,
	// ({ prompt } : { prompt: string }) => `Highly Detailed digital illustration about ${prompt}. Trending on Artstation.`,
	// ({ prompt } : { prompt: string }) => `${prompt}, photorealistic, smoky, hd, 4k, bloody, detailed`,
	({ prompt } : { prompt: string }) => `${prompt}, ultra realistic, focus, vaporwave aesthetics, cyberpunk, highly detailed, smooth, sharp, art by greg rutkowski and thomas kinkade`,
	({ prompt } : { prompt: string }) => `${prompt}, unreal engine, 8k, hyper realistic`,
	// ({ prompt } : { prompt: string }) => `${prompt}, evening, artistic, detailed, fantasy aesthetic`,
	({ prompt } : { prompt: string }) => `${prompt}, detailed, 8K, science fiction`
];

const randomPromptExpansions = ({ limit }: { limit: number }) => _.shuffle(promptExpansions).splice(0, Math.min(limit, promptExpansions.length));

export const createContentRequestsForPrompt = ({ prompt, ratio, creativity, parentRequestId } : { prompt: string, ratio?: number, creativity?: ContentCreationCreativity, parentRequestId?: string }): Models.ContentCreationRequest => {
	return {
		id: ulid(),
		dt_create: (new Date()).toUTCString(),
		prompt,
		promptVariationsCount: parseInt(process.env.REACT_APP_AI_PROMPT_VARIATIONS_PER_SERVICE ?? "0"),
		imageVariationsCount: parseInt(process.env.REACT_APP_IMG_VARIATIONS_PER_SERVICE ?? "0"),
		creativity,
		parentRequestId,
		ratio: ratio ?? 1
	}
}

const createContentRequestMatrixForPrompt = ({ prompts : promptsService }: ContentCreationServices) => async (request: Models.ContentCreationRequest): Promise<Models.ContentCreationRequest[]> => {
	const { id, prompt, promptVariationsCount } = request;
	const expansions = randomPromptExpansions({ limit: 3 });
	const promptVariations = (await promptsService.promptVariationsForPrompt(prompt, promptVariationsCount).catch(() => Array<string>())).concat([request.prompt]);
	return promptVariations
		.map((prompt) => [
			prompt,
			...(prompt.length > 20 ? [] : expansions.map( expansion => expansion({ prompt })))
		])
		.flat()
		.map( prompt => createContentRequestsForPrompt({ prompt, parentRequestId: id }));
}

const createContent = (services: ContentCreationServices) => async (request: Models.ContentCreationRequest, onResultsUpdated?: ((props: { request: Models.ContentCreationRequest, results: ContentCreationResult[] }) => void)): Promise<void> => {
	const {
		images: imagesService,
		results: resultsService
	} = services;
	let requests = await createContentRequestMatrixForPrompt(services)(request);

	console.log(`Requests Count: ${requests.length}`);
	console.log(`Expected to Generate: ${requests.length * 2 * parseInt(process.env.REACT_APP_IMG_VARIATIONS_PER_SERVICE ?? "1")}`)
	return Promise
		.all(
			requests.map(
				request => imagesService.create({ request }).catch((error) => [])
			)
		)
		.then( async results => {
			// await resultsService.storeResults(request, results);
			console.log(`Received Results: ${results}`)
			if (onResultsUpdated) onResultsUpdated({ request, results: results.flat() });
		})
}

export const useCases = (services: ContentCreationServices): UseCases => {
	return {
		createContent: createContent(services),
		createContentRequest: (prompt: string) => createContentRequestsForPrompt({ prompt })
	}
}
