import * as Domain from '../../domain';
import { DBSchema, openDB } from "idb";

interface ResultsDB extends DBSchema {
  results: {
    value: Domain.Content.Models.ContentCreationResult & { parentRequestId?: string };
    key: string;
    indexes: { 'byRootRequest': string };
  };
}

export const repository = async (): Promise<Domain.Content.Repositories.Results.Repository> => {
	const db = await openDB<ResultsDB>("ContentDatabase", 1, {
		upgrade(db) {
			const resultsStores = db.createObjectStore("results", { keyPath: "id" });
			resultsStores.createIndex("byRootRequest", "parentRequestId", { unique: false });
		}
	});
	
	const getResultsForRequest = async (request:  Domain.Content.Models.ContentCreationRequest) => db.getAllFromIndex("results", "byRootRequest", request.id);

	const storeResults = async (request: Domain.Content.Models.ContentCreationRequest, results: Domain.Content.Models.ContentCreationResult[]) => {
		const transaction = db.transaction("results")
		for (const result of results) {
			db.add("results", {...result, parentRequestId: request.id })
		}
		await transaction.done
		return
	}
	return {
		storeResults,
		storeResult: (request:  Domain.Content.Models.ContentCreationRequest, result: Domain.Content.Models.ContentCreationResult) => storeResults(request, [result]),
		getResultsForRequest
	}
}
