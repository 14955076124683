import { Repositories } from "..";
import { ContentCreationRequest, ContentCreationResult } from "../models";

export interface Service {
	create: (props: { request: ContentCreationRequest }) => Promise<ContentCreationResult[]>
}

export const factory = (repositories: Repositories.ImageCreation.Repository[]): Service => ({
	create: async ({ request: initialRequest }: { request: ContentCreationRequest }) => {
		const requests = Promise.all(repositories.map(async ({ create, generateRequestVariations: generatePromptVariations }) => {
			const variations = await generatePromptVariations({ request: initialRequest });
			const requestsToSend = variations.filter(({ prompt }) => prompt.length > 0).concat(initialRequest);
			const results: ContentCreationResult[][] = await Promise.all(requestsToSend.map((request) => create({ request })));
			console.log(`Results: ${results.flat()}`)
			return results.flat();
		}))
		return (await requests).flat();
	}
})