import {
	Container,
	CssBaseline,
	Grid,
	Typography
} from '@mui/material';

import { ThemeProvider } from '@mui/material/styles';
import { appTheme } from "../../themes/theme";

// const theme = createTheme();
interface Props  { 
	children: React.ReactNode
}

export default function AppContainer(props: Props) {
  return (
    <ThemeProvider theme={appTheme}>
			<CssBaseline enableColorScheme />
			<Grid direction="row" container component="main" sx={{ height: '100vh', width: '100vw' }}>
				<Grid item xs={12} sx={{ height: "100px"}}>
					<Container sx={{ display: { sm: "none" } }} style={{ margin: "0", padding: "0" }}>
						<Typography variant="h2" component="h2" style={{ padding: "50px"}}>
							Nav Bar
						</Typography>
					</Container>
					<Container sx={{ display: { xs: "none", sm: "block" } }} style={{ margin: "0", padding: "0" }}>
						<Typography variant="h1" component="h1" style={{ padding: "50px"}}>
							Nav Bar
						</Typography>
					</Container>
					
				</Grid>
				<Grid item xs={12} style={{ padding: "50px"}} sx={{ height: "90%" }}>
					{props.children}
				</Grid>
			</Grid>
    </ThemeProvider>
  );
}