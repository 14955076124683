import { useEffect, useState } from 'react';
import {
	Collapse,
	Container,
	CssBaseline,
	Grid,
	Paper,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Typography
} from '@mui/material';

import {
	ExpandLess,
	ExpandMore,
} from "@mui/icons-material";

import { PromptInputBar, ResultsList } from '../1-molecules'
import * as Domain from '../../domain';
import * as Repositories from '../../repositories';
import { ThemeProvider } from '@mui/material/styles';
import { appTheme } from "../../themes/theme";
// const theme = createTheme();

interface HistoryListItemProps {
	id: string,
	title: string,
	date: string,
	onSelect: () => void,
	selected: boolean
}

const HistoryListItem = ({ id, title, date, onSelect, selected }:HistoryListItemProps) => {
	return (
		<ListItem disablePadding selected={selected} key={id} onClick={ () => onSelect() } >
			<ListItemButton>
				<ListItemText primary={`${title.slice(0, 140)}${title.length > 140 ? "..." : ""}`} secondary={date} style={{margin: "10px 0" }} />
			</ListItemButton>
		</ListItem>
	)
}

interface HistoryListProps {
	history: Domain.Content.Models.ContentCreationRequest[],
	onItemSelected: (param: { item: Domain.Content.Models.ContentCreationRequest, index: number }) => void,
	selectedIndex?: number
}

const HistoryList = ({ history, onItemSelected, selectedIndex }: HistoryListProps) => {
	return (
		<Paper sx={{ bgcolor: 'background.paper' }}>
			<Typography variant="h3" component="h3">
				History
			</Typography>
			<List>
				{
					history.map((item, index) =>
						<HistoryListItem
								id={item.id}
								title={item.prompt}
								date={item.dt_create}
								onSelect={ () => onItemSelected({ item, index }) }
								selected={selectedIndex === index}
							/>
					)
				}
			</List>		
		</Paper>
	)
}

const CollapsableHistoryList = ({ history, onItemSelected, selectedIndex }: HistoryListProps) => {
	const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
	
	return (
		<Paper sx={{ bgcolor: 'background.paper' }}>
			<ListItemButton onClick={handleClick}>
				<Typography variant="h3" component="h3" sx={{ width: '95%' }}>
					History
				</Typography>
        {/* <ListItemText primary="Inbox" /> */}
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
			<Collapse in={open} unmountOnExit>
				<List>
					{
						history.map((item, index) =>
							<HistoryListItem
									id={item.id}
									title={item.prompt}
									date={item.dt_create}
									onSelect={ () => onItemSelected({ item, index }) }
									selected={selectedIndex === index}
								/>
						)
					}
				</List>	
			</Collapse>
		</Paper>
	)
}

interface Services {
	images: Domain.Content.Services.ContentCreation.Service;
	prompts: Domain.Content.Services.PromptVariations.Service;
	results: Domain.Content.Services.Results.Service;
}

export default function CreationBoard() {

	const apiKey = process.env.REACT_APP_API_KEY as string;
	
	const [useCase, setUseCase] = useState<Domain.Content.UseCases | undefined>(undefined);

	const [services, setServices] = useState<Services | undefined>(undefined);
	const [history, setHistory] = useState<Domain.Content.Models.ContentCreationRequest[]>([]);

	const [selectedItemIndex, setSelectedItem] = useState<number | undefined>(undefined);
	const [results, setResults] = useState<{ [key: string]: Domain.Content.Models.ContentCreationResult[] }>({});

	useEffect(() => {
		const updateServices = async () => {
			const services = {
				images: Domain.Content.Services.ContentCreation.factory([
					// Repositories.OpenAI.DallE.repository({ apiKey }),
					Repositories.StabilityAI.StableDiffusion.repository({ apiKey })
				]),
				prompts: Domain.Content.Services.PromptVariations.factory(Repositories.OpenAI.Gpt3.repository({ apiKey })),
				results: Domain.Content.Services.Results.factory(await Repositories.LocalStorage.IndexedDBRepository.repository())
			};
			setServices(services);
			setUseCase(Domain.Content.useCases(services));
		}
		updateServices();
	})

	const onItemSelected = async ({ index }: { index?: number}) => {
		console.log(`Selected item: ${index} - Current Selection: ${selectedItemIndex}`)
		if (index !== selectedItemIndex) {
			setSelectedItem(index)
		}
	}

	const onHistoryUpdated = (newHistory: Domain.Content.Models.ContentCreationRequest[]) => {
		setHistory(newHistory);
		onItemSelected({ index: (newHistory.length > 0 ? 0 : undefined) })
	}

	const sendNewImageCreationRequest = async ({ prompt }: { prompt: string }) => {
		const newContentCreationRequest = useCase?.createContentRequest(prompt);
		if (newContentCreationRequest === undefined) return;
		onHistoryUpdated([
			newContentCreationRequest,
			...history
		]);
		await useCase?.createContent(
			newContentCreationRequest,
			(({ request: initialRequest, results }) => { updateDisplayedResults(initialRequest, results) })
		)
	}

	const updateDisplayedResults = async (request: Domain.Content.Models.ContentCreationRequest, newResults: Domain.Content.Models.ContentCreationResult[]) => {
		console.log("Retrieving Results")
		const previousResults = results[request.id] ?? []
		setResults({
			...results,
			[request.id]: (results[request.id] ?? []).concat(previousResults.concat(newResults))
		});
	}

  return (
    <ThemeProvider theme={appTheme}>
		<CssBaseline enableColorScheme />
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<PromptInputBar onContentCreationRequested={(newPrompt) => sendNewImageCreationRequest({ prompt: newPrompt }) }/>
				</Grid>
				<Grid item xs={12} md={12} lg={4} xl={3}>
					{ (history.length > 0) && <Container sx={{ display: { lg: "none" } }} style={{ margin: "0", padding: "0" }}>
						<CollapsableHistoryList history={history} onItemSelected={onItemSelected} selectedIndex={selectedItemIndex} />
						</Container>
					}
					{ (history.length > 0) && <Container sx={{ display: { xs: "none", lg: "block" } }} style={{ margin: "0", padding: "0" }}>
							<HistoryList history={history} onItemSelected={onItemSelected} selectedIndex={selectedItemIndex} />
						</Container>
					}
				</Grid>
				<Grid item xs={12} md={12} lg={8} xl={9}>
				{ (selectedItemIndex !== undefined) &&
					<div>
						<Paper>
						<Typography variant="h2" component="h2">
							Generated Content
						</Typography>
						<Typography variant="body1" component="p">
							{ history[selectedItemIndex].prompt }
						</Typography>
					</Paper>
						<ResultsList results={ results[history[selectedItemIndex]?.id] ?? [] } />
					</div>	
				}
				</Grid>
			</Grid>
    </ThemeProvider>
  );
}