import { createTheme } from "@mui/material/styles";

export const appTheme = createTheme({
  palette: {
		text: {
			primary: "#EEEEEE",
			secondary: "#EEEEEE"
		},
		background: {
			default: "#282c34",
      paper: '#2c384e',
		}
  },
	components: {
    MuiListItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#99ACCF",
            color: "#2c384e"
          },
          '&.Mui-focusVisible': {
            backgroundColor: "#99ACCF",
          },
          '&:hover': {
            backgroundColor: "#99ACCF",
            color: "#2c384e"
          },
          '&.Mui-selected:hover': {
            backgroundColor: "#99ACCF",
          },
          '&.MuiListItem-disabled': {
            opacity: 0.5,
          },
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: '20px'
        }
      }
    },
		MuiTextField: {
      styleOverrides: {
        root: {
          '& label': {
            color: '#EEEEEE',
          },
          '& label.Mui-focused': {
            color: '#EEEEEE',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#EEEEEE',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#EEEEEE',
            },
            '&:hover fieldset': {
              borderColor: '#EEEEEE',
              borderWidth: '0.15rem',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#EEEEEE',
            },
          },
        },
      },
    }
}
});