import * as Domain from '../../domain';
import ky from 'ky';

export const repository = ({ apiKey }: { apiKey: string }): Domain.Content.Repositories.PromptVariations.Repository => {
	const api = ky.extend({
		hooks: {
			beforeRequest: [
				request => {
					request.headers.set('X-API-KEY', apiKey);
				}
			]
		}
	});
	
	return {
		promptVariationsForPrompt: async (prompt: string, promptVariationsCount: number): Promise<string[]> => {
			const url = `/api/domain/repositories/open-ai/gpt3`;
			const result = await api.post(url, {
				json: {
					prompt,
					promptVariationsCount
				},
				retry: {
					limit: 10,
					methods: ['post'],
					statusCodes: [408, 413]
				},
				timeout: 120000
			}).json() as string[];
			return result;
		}
	}
}
