import { Repositories } from ".."
import { ContentCreationRequest, ContentCreationResult } from "../models";

export interface Service {
	storeResults: (request: ContentCreationRequest, results: ContentCreationResult[]) => Promise<void>
	storeResult: (request: ContentCreationRequest, result: ContentCreationResult) => Promise<void>
	getResultsForRequest: (request: ContentCreationRequest) => Promise<ContentCreationResult[]>
}

export const factory = (repository: Repositories.Results.Repository): Service => ({
	storeResults: repository.storeResults,
	storeResult: repository.storeResult,
	getResultsForRequest: repository.getResultsForRequest
})