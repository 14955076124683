import { useEffect, useState } from 'react';
import {
	CssBaseline,
	IconButton,
	ImageList,
	ImageListItem,
	ImageListItemBar
} from '@mui/material';

import {
	CloudDownload as CloudDownloadIcon
} from "@mui/icons-material";

import { ThemeProvider } from '@mui/material/styles';
import { appTheme } from "../../../themes/theme";
import * as Domain from '../../../domain';

interface Props {
	results: Domain.Content.Models.ContentCreationResult[]
}

export default function ResultsList({ results }: Props) {
	
	const breakpoints = {
		xs: 0,
		sm: 600,
		md: 960,
		lg: 1280,
		xl: 1920
	}
	
	const getColumns = (width: number) => {
		if (width < breakpoints.sm) {
			return 1
		} else if (width < breakpoints.md) {
			return 2
		} else if (width < breakpoints.lg) {
			return 3
		} else if (width < breakpoints.xl) {
			return 3
		} else {
			return 5
		}
	}
	
	const [columns, setColumns] = useState(getColumns(window.innerWidth))
	const updateDimensions = () => {
		setColumns(getColumns(window.innerWidth))
	}
	
	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);
	
	return (
		<ThemeProvider theme={appTheme}>
		<CssBaseline enableColorScheme />
		<ImageList variant="masonry" cols={columns} gap={8}>
		{ results.map(({ id, request, imageURL, imageBase64 }) => (
			(imageURL || imageBase64) && <ImageListItem key={id}>
				{ (imageURL !== undefined) && <img
						src={`${imageURL}`}
						alt={request.prompt}
						loading="lazy"
					/>
				}
				{ (imageBase64 !== undefined) && <img
						src={`data:image/png;base64,${imageBase64}`}
						alt={request.prompt}
						loading="lazy"
					/>
				}
				<ImageListItemBar
					title={request.prompt}
					subtitle={request.prompt}
					actionIcon={
						<IconButton
						sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
						aria-label={`download ${request.prompt}`}
						>
						<CloudDownloadIcon />
						</IconButton>
					}
				/>
				</ImageListItem>
			))
		}
		</ImageList>
		</ThemeProvider>
	);
}