import {
	useEffect,
	useState
} from 'react';
import {
	Button,
	CssBaseline,
	Grid,
	TextField
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { appTheme } from "../../../themes/theme";

interface Props {
	onContentCreationRequested: (prompt: string) => void
}

export default function PromptInputBar({ onContentCreationRequested }: Props) {

	const [prompt, setPrompt] = useState<string | undefined>()

	const onTextInputChange = (newPrompt?: string) => {
		setPrompt(newPrompt);
	}

	const onClickCreate = () => {
		if (prompt !== undefined && prompt.length > 0) {
			onContentCreationRequested(prompt)
			onTextInputChange(undefined)
		}
	}

	useEffect(() => {

	}, [prompt])

  return (
    <ThemeProvider theme={appTheme}>
		<CssBaseline enableColorScheme />
			<Grid container direction={'row'} spacing={1}>
				<Grid item xs={9} xl={10}>
					<TextField
						id="outlined-basic"
						label="Input Text Field"
						variant="outlined"
						fullWidth
						onChange={ (event) => onTextInputChange(event.target.value) }
						defaultValue={prompt ?? ""}
						value={prompt ?? ""}
					/>
				</Grid>
				<Grid item xs={3} xl={2} >
					<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{ height: "50px" }}
							onClick={ () => onClickCreate() }
						>
							Create
					</Button>
				</Grid>
			</Grid>
    </ThemeProvider>
  );
}