import ky from 'ky';
import * as Domain from '../../domain';
import { ContentCreationResult } from '../../domain/content/models';

export const repository = ({ apiKey }: { apiKey: string }): Domain.Content.Repositories.ImageCreation.Repository => {
	const api = ky.extend({
		hooks: {
			beforeRequest: [
				request => {
					request.headers.set('X-API-KEY', apiKey);
					request.headers.set('Content-Type', `application/json`);
					request.headers.set('Accept', `application/json`);
				}
			]
		}
	});
	
	return {
		create: async (props: { request: Domain.Content.Models.ContentCreationRequest }): Promise<ContentCreationResult[]> => {
			const { request } = props;
			const url = `/api/domain/repositories/stability-ai/stable-diffusion`;
			const result = await api.post(url, {
				json: request,
				retry: {
					limit: 10,
					methods: ['post'],
					statusCodes: [408, 413]
				},
				timeout: 120000
			}).json() as Domain.Content.Models.ContentCreationResult[];
			return result;
		},
		generateRequestVariations: async (props: { request: Domain.Content.Models.ContentCreationRequest }) => {
			const creativityLevels: Domain.Content.Models.ContentCreationCreativity[] = [
				// Domain.Content.Models.ContentCreationCreativity.mad,
				Domain.Content.Models.ContentCreationCreativity.promethean,
				Domain.Content.Models.ContentCreationCreativity.creative,
				Domain.Content.Models.ContentCreationCreativity.predictable
			].filter( creativity => creativity !== props.request.creativity );
			return creativityLevels
				.map((creativityLevel) => Domain.Content.createContentRequestsForPrompt({
					prompt: props.request.prompt,
					creativity: creativityLevel,
					parentRequestId: props.request.id
				}))
		}
	}
}
